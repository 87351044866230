import React from "react"
import { RouteComponentProps } from "@reach/router"
import { connect, ConnectedProps } from "react-redux"
import { navigate, Link } from "gatsby"

import { store } from "@/types/interface"
import { excursionLessonFind } from "../../actions"
import { Slider, Image, SEO } from "../../components/common"
import { ExcursionDetailPanel } from "../../components/excursion-detail"
import { useWindowSize } from "../../utils/useWindowSize"
import styles from "./excursion-detail.module.css"

const connector = connect(
  // mapStateToProps
  ({ excursionLesson }: { excursionLesson: store.ExcursionLesson }) => ({
    lesson: excursionLesson.excursionLesson,
  }),
  // mapDispachToProps
  { excursionLessonFind }
)

type Props = ContainerProps & {
  targetRef: React.RefObject<HTMLDivElement>
  dimensions: {
    width: number
    height: number
  }
  size: ReturnType<typeof useWindowSize>
}

const ExcursionDetail: React.FC<Props> = props => {
  const { lesson } = props

  return (
    <>
      <SEO title={`Culture House ${lesson?.title}`} description={lesson?.description} />
      <h1 className={styles.title}>{lesson?.title}</h1>
      <div className={styles.headerContainer}>
        <div className={styles.date}>{lesson && getDate(lesson.lessonDay)}</div>
        <div className={styles.day}>
          {lesson && getDayOfWeek(lesson.lessonDay)}
        </div>
        <div className={styles.time}>{lesson && lesson.time}</div>
        <Link to={`form/excursion/${lesson?._id}`} className={styles.proposal}>
          申し込み
        </Link>
      </div>
      <div className={styles.topContainer}>
      <div className={styles.sliderContainer} ref={props.targetRef}>
        {props.dimensions.width != 0 && lesson?.imagePaths.length !== 0 ? (
          <Slider
            key={lesson?._id}
            slideWidth={props.dimensions.width}
            slideHeight={props.dimensions.height}
            photos={lesson ? lesson.imagePaths : []}
            isStatic={false}
            hasSelector={
              props.size.width && props.size.width < 768 ? false : true
            }
          />
        ) : (
          <Image
            filename="no-photo.jpg"
            width={props.dimensions.width}
            height={props.dimensions.height}
          />
        )}
      </div>
      <p className={styles.description}>{lesson?.description}</p>
      </div>
      {lesson && <ExcursionDetailPanel key={lesson._id} lesson={lesson} />}
      <Link
        to={`form/excursion/${lesson?._id}`}
        className={`${styles.proposal} ${styles.footer}`}
      >
        申し込み
      </Link>
    </>
  )
}

type ContainerProps = RouteComponentProps<{ excursionLessonId: string }> &
  ConnectedProps<typeof connector>

const Container: React.FC<ContainerProps> = props => {
  const targetRef = React.useRef<HTMLDivElement>(null)
  const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 })
  const size = useWindowSize()

  React.useEffect(() => {
    // get course detail information
    if (props.excursionLessonId) {
      props.excursionLessonFind(props.excursionLessonId)
    } else {
      navigate("/")
    }
  }, [])

  React.useEffect(() => {
    // get target ref dimensions
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight,
      })
    }
  }, [size])

  return (
    <ExcursionDetail
      {...props}
      targetRef={targetRef}
      dimensions={dimensions}
      size={size}
    />
  )
}

const getDayOfWeek = (strDate: string): string => {
  const date = new Date(strDate)
  return ["日", "月", "火", "水", "木", "金", "土"][date.getDay()]
}

const getDate = (strDate: string): string => {
  const date = new Date(strDate)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  return year + "/" + month + "/" + day
}

export default connector(Container)
