import React from "react"

import { Image } from "../common"
import { store } from "@/types/interface"
import styles from "./excursion-detail-panel.module.css"

type Props = {
  lesson: NonNullable<store.ExcursionLesson["excursionLesson"]>
}

const ExcursionDetailPanel: React.FC<Props> = ({ lesson }) => {
  return (
    <div className={styles.panelContainer}>
      {lesson.teacherDescription && (
        <>
          <div className={styles.subTitle}>
            <Image className={styles.icon} filename="teacher.png" />
            <h3 className={styles.teacher}>講師</h3>
          </div>
          <p>{lesson.teacherDescription}</p>
        </>
      )}
      <div className={styles.subTitle}>
        <Image className={styles.icon} filename="money.png" />
        <h3 className={styles.fee}>参加費</h3>
      </div>
      <p>{`会員 ${lesson.memberFee.toLocaleString()}円、一般 ${lesson.publicFee.toLocaleString()}円`}</p>
      <div className={styles.subTitle}>
        <Image className={styles.icon} filename="place.png" />
        <h3 className={styles.place}>場所</h3>
      </div>
      <p>{lesson.place}</p>
      {lesson.memo && (
        <>
          <div className={styles.subTitle}>
            <Image className={styles.icon} filename="memo.png" />
            <h3 className={styles.place}>備考</h3>
          </div>
          <p>{lesson.memo}</p>
        </>
      )}
    </div>
  )
}

export default ExcursionDetailPanel
